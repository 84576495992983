import { createSlice, createSelector } from '@reduxjs/toolkit'
import axios from '../../axios'
import { IStateRedux } from '../../ReduxStore'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

export interface IRequest {
  errorCode: number
  message: string
  fetching: boolean
}

export interface IState {
  usuario: any
  token: string
  request: IRequest
}

const token = localStorage.getItem('token')

export const initialState: IState = {
  usuario: null,
  token: token && token !== 'undefined' ? token : null,
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const reducers = {
  loginFetch(state: IState) {
    state.request.fetching = true
  },
  loginSuccess(state: IState, { payload }: any) {
    return {
      ...state,
      request: {
        ...initialState.request,
        fetching: false,
      },
      usuario: payload.usuario,
      token: payload.token,
    }
  },
  loginError(state: IState, { payload }: any) {
    state.request = {
      errorCode: payload.errorCode,
      message: payload.message,
      fetching: false,
    }
  },
  logout(state: IState) {
    state.usuario = null
    state.token = null
  },
}

const { reducer: authReducer, actions } = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers,
})

const authActions = {
  ...actions,
  login({ cpf, pwd }: { cpf: string; pwd: string }) {
    return async (dispatch) => {
      dispatch(actions.loginFetch())
      try {
        const { token } = await axios.Usuarios.login(cpf, pwd)
        dispatch(actions.loginSuccess({ token } as any))
      } catch (error) {
        const message = error?.response?.data?.message
        toast.error(message)
        dispatch(actions.loginError({ errorCode: 400, message } as any))
      }
    }
  },
}

export const getUsuarioLogado = createSelector(
  (s: IStateRedux) => s.auth.token,
  /* #eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  (token) => jwtDecode(token) as any
)

export const isAuditorPerfil = createSelector(
  getUsuarioLogado,
  (usuario) => usuario?.permissoes?.some((f) => f.perfil === 'AUDITOR') ?? false
)

export { authReducer, authActions }
