import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router'
import {
  Segment as SegmentComp,
  Tab,
  Button,
  Icon,
  Table as TableComp,
  Modal,
  Checkbox,
} from 'semantic-ui-react'
import { Modal as ModalAntd, Radio as RadioAntd, Button as ButtonAntd, Card } from 'antd'
import { format, differenceInMinutes, parseISO, startOfToday, startOfDay } from 'date-fns'
import { NavEstrutural } from '../../components/NavEstrutural'
import { Show } from '../../components/Basic'
import { Avatar } from '../../components/Avatar'
import { DisplayFlex } from '../../components/Styles'
import { useDecorateBlobWithSas } from '../../components/UseDecorateBlobWithSas'
import { ReactComponent as CopyIcon } from '../../assets/copy.svg'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { copyToClipboard } from '../../util/copyToClipboard'
import { ProgressoStore } from './ProgressoStore'
import { TextFieldInput } from '../../components/TextField'
import { MatriculaModel, STATUS_MATRICULA } from '../../models/MatriculaModel'
import iconSearch from '../../assets/search.svg'
import { encodeHashCobranca } from '@hyperdev-tech/hash-cobranca'
import axios from 'axios'
import { ConfirmAlert } from '../../components/ConfirmAlert'
import { CURSOS } from '../../models/CursoModel'
import { isAuditorPerfil } from '../Auth/AuthReducer'
import { useSelector } from 'react-redux'

const Table = TableComp as any
const Segment = SegmentComp as any

interface ProgressoParams {
  cursoId
  usuarioId
}

export const Progresso = () => {
  return (
    <ProgressoStore.Provider>
      <Init />
      <div className="ui container">
        <NavEstrutural>Progresso</NavEstrutural>
        <BotaoVoltar />
        <Segment color="purple" style={{ marginTop: 25 }}>
          <IdentificacaoAluno />
        </Segment>
        <Segment color="orange" style={{ marginTop: 25, marginBottom: 40 }}>
          <h2>Acompanhamento de progresso do aluno</h2>
          <Tab
            panes={[
              { menuItem: 'Estatisticas de estudo', render: () => <EstatisticasDeEstudo /> },
              { menuItem: 'Sessões de estudo', render: () => <Sessoes /> },
              { menuItem: 'Acompanhamento', render: () => <Acompanhamento /> },
              { menuItem: 'Validações Faciais', render: () => <ValidacoesFaciais /> },
              { menuItem: 'Avaliações', render: () => <Avaliacoes /> },
              { menuItem: 'Outros Cursos', render: () => <OutrosCursos /> },
            ]}
          />
        </Segment>
      </div>
    </ProgressoStore.Provider>
  )
}

const Init = () => {
  const { dadosAluno } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const { usuarioId } = useParams<ProgressoParams>()
  const queryParams = new URLSearchParams(useLocation().search)
  const matriculaId = Number(queryParams.get('matriculaId'))

  useEffect(() => {
    dispatch(ProgressoStore.thunks.getDados(usuarioId, matriculaId))
    dispatch(ProgressoStore.thunks.getEstados())
  }, [])

  useEffect(() => {
    if (!dadosAluno?.estado) return
    dispatch(ProgressoStore.thunks.getMunicipios({ estados: dadosAluno.estado }))
  }, [dadosAluno?.estado])

  return null
}

const IdentificacaoAluno = () => {
  const isAuditor = useSelector(isAuditorPerfil)
  const { DadosAlunoContainer, DadosMatriculaContainer, DadosCobrancaContainer } =
    IdentificacaoAluno
  const { dadosAluno, matricula } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const [editing, setEditing] = useState(false)

  const updateDadosAluno = (e) => {
    dispatch(ProgressoStore.actions.updateDadosAluno({ [e.target.name]: e.target.value }))
  }

  const saveChanges = () => {
    dispatch(ProgressoStore.thunks.updateDadosAluno())
    setEditing(false)
  }

  const cancelChanges = () => {
    dispatch(ProgressoStore.thunks.getDadosAluno())
    setEditing(false)
  }

  if (!dadosAluno || !matricula) return null
  const urlCert = `https://app.newdriver.com.br/certificado/${matricula.identificacaoCertificado}`

  return (
    <div>
      <DisplayFlex marginBetween={10}>
        <DadosAlunoContainer>
          <div className="entire-col">
            <DisplayFlex verticalCenter>
              <Avatar usuario={dadosAluno} circular={true} />
            </DisplayFlex>
          </div>
          <TextFieldInput label="Aluno Id" disabled={true} value={dadosAluno.id} />
          <TextFieldInput
            name="nome"
            label="Nome"
            disabled={!editing}
            value={dadosAluno.nome}
            onChange={updateDadosAluno}
          />
          <TextFieldInput
            mask="cpf"
            name="cpf"
            label="CPF"
            disabled={true}
            value={dadosAluno.cpf}
          />
          <TextFieldInput
            name="telefone"
            label="Telefone"
            disabled={!editing}
            value={dadosAluno.telefone}
            onChange={updateDadosAluno}
          />
          <TextFieldInput
            name="email"
            label="E-mail"
            disabled={!editing}
            value={dadosAluno.email}
            onChange={updateDadosAluno}
          />
          <TextFieldInput
            type="date"
            name="dataNascimento"
            label="Data de Nascimento"
            disabled={!editing}
            value={formatDate(dadosAluno.dataNascimento, 'yyyy-MM-dd')}
            onChange={updateDadosAluno}
            className="entire-col"
          />
          <TextFieldInput
            name="dataPrimeiraHabilitacao"
            label="Data da Primeira Habilitação"
            type="date"
            disabled={!editing}
            value={formatDate(dadosAluno.dataPrimeiraHabilitacao, 'yyyy-MM-dd')}
            onChange={updateDadosAluno}
            className="entire-col"
          />

          <TextFieldInput
            name="categoriaCnh"
            label="Categoria CNH"
            disabled={!editing}
            value={dadosAluno.categoriaCnh}
            onChange={updateDadosAluno}
          />

          <ComboBoxEstados disabled={!editing} />

          <ComboBoxMunicipios disabled={!editing} />

          <Show condition={!isAuditor}>
            <Checkbox
              label={'Ignorar Validação Serpro'}
              disabled={!editing}
              checked={!!dadosAluno.ignorarValidacaoSerpro}
              onChange={(e) => {
                dispatch(
                  ProgressoStore.actions.updateDadosAluno({
                    ignorarValidacaoSerpro: !dadosAluno.ignorarValidacaoSerpro,
                  })
                )
              }}
              className="entire-col"
            />
          </Show>

          <div
            className="entire-col"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            {editing ? (
              <div>
                <Button className="entire-col" onClick={saveChanges}>
                  Salvar
                </Button>
                <Button className="entire-col" onClick={cancelChanges}>
                  Cancelar
                </Button>
              </div>
            ) : (
              <Button className="entire-col" onClick={() => setEditing(true)}>
                Editar
              </Button>
            )}
            <GerarNovoToken />
            <AcoesDetran />
          </div>
        </DadosAlunoContainer>

        <div>
          <Avisos />
          <DadosMatriculaContainer>
            <Label label="Matricula ID" value={matricula?.id} />
            <Label label="Curso" value={matricula?.curso?.nomeReduzido} />
            <Label label="UF" value={matricula.uf} />
            <Label label="Status Detran" value={matricula.statusDetran.replace(/_/g, ' ')} />
            {matricula?.turmaId && <Label label="Turma ID" value={matricula?.turmaId} />}
            {matricula?.parceiro && <Label label="Parceiro" value={matricula?.parceiro?.nome} />}
            {matricula?.parceiro && (
              <Label label="Parceiro CPF/CNPJ" value={matricula?.parceiro?.cpfCnpj} />
            )}
            {matricula?.captador && <Label label="Captador" value={matricula?.captador?.nome} />}
            {matricula?.captador && (
              <Label label="Captador CPF/CNPJ" value={matricula?.captador?.cpfCnpj} />
            )}
            <Label label="Data do Início do Curso" value={formatDate(matricula.dataInicioCurso)} />
            <Label label="Data do Fim do Curso" value={formatDate(matricula.dataFimCurso)} />
            <Label
              label="Data do Início do Módulo Teórico"
              value={formatDate(matricula.dataInicioModuloTeorico)}
            />
            <Label
              label="Data do Fim do Módulo Teórico"
              value={formatDate(matricula.dataFimModuloTeorico)}
            />
            <Label
              label="Data da Validação Inicial"
              value={formatDate(matricula.dataValidacaoFacialInicial)}
            />
            <Label
              label="Data da Validação Final"
              value={formatDate(matricula.dataValidacaoFacialFinal)}
            />
            <Label label="Nota" value={matricula.nota} className="entire-col" />
            <Label
              label="Identificação Certificado"
              value={matricula?.identificacaoCertificadoRetornado}
              className="entire-col"
            />
            {matricula?.identificacaoCertificadoRetornadoTeorico && (
              <Label
                label="Identificação Certificado Teórico"
                value={matricula?.identificacaoCertificadoRetornadoTeorico}
                className="entire-col"
              />
            )}
            <Label
              label="Certificado"
              value={
                <a target="_blank" rel="noreferrer" href={urlCert}>
                  {urlCert}
                </a>
              }
              className="entire-col"
            />

            <PermiteEmissaoCertificado />
            <ReenviarConclusao />
          </DadosMatriculaContainer>
          <DadosCobrancaContainer>
            <InformacoesCobranca />
          </DadosCobrancaContainer>
        </div>
      </DisplayFlex>
    </div>
  )
}
IdentificacaoAluno.DadosAlunoContainer = styled.div`
  display: grid;
  width: 1000px;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  background-color: #f3e4d48d;
  border-radius: 20px;
  border: 1px solid #f7c286;

  .entire-col {
    grid-column: 1 / 3;
  }
`
IdentificacaoAluno.DadosMatriculaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  background-color: #f3e4d48d;
  border-radius: 20px;
  border: 1px solid #f7c286;
  margin-bottom: 14px;

  .entire-col {
    grid-column: 1 / 3;
  }
`
IdentificacaoAluno.DadosCobrancaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  background-color: #f3e4d48d;
  border-radius: 20px;
  border: 1px solid #f7c286;

  .entire-col {
    grid-column: 1 / 3;
  }
`

function Avisos() {
  const { Container, Card } = Avisos
  const { matricula } = ProgressoStore.useState()
  const isCursoPraticoSemParceiro =
    [
      STATUS_MATRICULA.AGUARDANDO_CONCLUSAO_MODULO_PRATICO,
      STATUS_MATRICULA.AGUARDANDO_MATRICULA_MODULO_PRATICO,
    ].includes(matricula.statusDetran) &&
    !matricula.parceiroCursoPraticoId &&
    !matricula.parceiroId

  return (
    <Show condition={[isCursoPraticoSemParceiro].includes(true)}>
      <Container>
        <Card
          title={<strong>Avisos</strong>}
          size="small"
          headStyle={{ color: 'red' }}
          bordered={false}
        >
          <Show condition={isCursoPraticoSemParceiro}>
            <p>
              <b>Atenção:</b> O aluno finalizou o modulo teórico e está aguardando matrícula e
              conclusão no curso prático. Informe o aluno para procurar o parceiro do curso prático.
              E fazer a busca pelo CPF do aluno no formulário de matrícula do curso prático, para
              lançar a matrícula no sistema do Detran.
            </p>
          </Show>
        </Card>
      </Container>
    </Show>
  )
}
Avisos.Container = styled.div`
  background-color: #f3e4d48d;
  border-radius: 20px;
  border: 1px solid #f7c286;
  margin-bottom: 14px;
  padding: 15px;
`
Avisos.Card = styled(Card)`
  background-color: #f8f0e7;
`

function PermiteEmissaoCertificado() {
  const dispatch = ProgressoStore.useDispatch()
  const { permiteEmissaoCertificado, referenciaPagamento, id } = ProgressoStore.useState().matricula
  const { loading } = ProgressoStore.useState()
  function handlePermiteEmissaoCertificado(e: any) {
    dispatch(ProgressoStore.actions.changePermiteEmissaoCertificado(e.target.value))
  }
  function handlePermiteCertificado() {
    if (id) {
      dispatch(ProgressoStore.thunks.updatePermissaoCertificado(id, permiteEmissaoCertificado!))
    }
  }

  return (
    <div>
      Permite Emissão de Certificado?
      <RadioAntd.Group
        onChange={handlePermiteEmissaoCertificado}
        value={!!permiteEmissaoCertificado}
      >
        <RadioAntd value={true}>Permite</RadioAntd>
        <RadioAntd value={false}>Não Permite</RadioAntd>
      </RadioAntd.Group>
      <Show condition={referenciaPagamento}>
        <Button onClick={handlePermiteCertificado} loading={loading}>
          Salvar
        </Button>
      </Show>
    </div>
  )
}

function ReenviarConclusao() {
  const dispatch = ProgressoStore.useDispatch()
  const { loading, matricula } = ProgressoStore.useState()
  const [modulo, setModulo] = useState<'TEORICO' | 'PRATICO'>('TEORICO')
  const isCursoMoto = [
    CURSOS.MOTOFRETISTA,
    CURSOS.MOTOTAXISTA,
    CURSOS.ATUALIZACAO_MOTOFRETISTA,
    CURSOS.ATUALIZACAO_MOTOTAXISTA,
  ].includes(matricula.cursoId)

  const handleReenviarConclusao = async () => {
    const result = await ConfirmAlert({
      title: 'Deseja reenviar a conclusão?',
      content: 'Ao reenviar a conclusão, o número do certificado será alterado!',
    })
    if (!result) return

    await dispatch(
      ProgressoStore.thunks.reenviarConclusao(matricula, isCursoMoto ? modulo : 'TEORICO')
    )
  }

  return (
    <Show condition={matricula.statusDetran === STATUS_MATRICULA.FINALIZADO}>
      <div className="entire-col">
        <Show condition={isCursoMoto}>
          <RadioAntd.Group onChange={(e) => setModulo(e.target.value)} value={modulo}>
            <RadioAntd value="TEORICO">Módulo Teórico</RadioAntd>
            <RadioAntd value="PRATICO">Módulo Prático</RadioAntd>
          </RadioAntd.Group>
        </Show>
        <Button onClick={handleReenviarConclusao} loading={loading}>
          Reenviar Conclusão
        </Button>
      </div>
    </Show>
  )
}

const InformacoesCobranca = () => {
  const { matricula } = ProgressoStore.useState()
  const linkCobranca = `https://rep.hyperpay.com.br/cobranca/${encodeHashCobranca(
    +matricula.referenciaPagamento
  )}`

  if (matricula.turmaId) {
    return (
      <div className="entire-col">Aluno pertence a uma turma e não possui dados de cobrança!</div>
    )
  }

  const statusCobranca = (() => {
    if (!matricula) return ''
    if (!matricula.cobranca) return 'EXCLUÍDA'
    if (matricula.dataPagamento || matricula.cobranca?.dataPagamento) return 'PAGA'
    if (startOfDay(new Date(matricula?.cobranca?.dataVencimento)) <= startOfToday())
      return 'VENCIDA'
    return 'EM ABERTO'
  })()

  return (
    <>
      <Label label="Referência da Cobrança" value={matricula?.referenciaPagamento} />
      <Label label="Status da Cobrança" value={statusCobranca} />
      <Label
        label="Data do Pagamento"
        value={formatDate(matricula?.cobranca?.dataPagamento ?? '-')}
      />
      <Label
        label="Data do Vencimento"
        value={formatDate(matricula?.cobranca?.dataVencimento ?? '-')}
      />
      <Label
        label="Link da cobrança"
        value={
          <a target="_blank" rel="noreferrer" href={linkCobranca}>
            {linkCobranca}
          </a>
        }
        className="entire-col"
      />

      <AlteracaoValor />
      <AplicarCupom />
      <ReemitirCobranca />
      <Cupom />
      <Isencao />
    </>
  )
}

const ComboBox = () => <></>
ComboBox.ContainerComboBox = styled.div`
  position: relative;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 14px;
  font-size: 0.9rem;
`
ComboBox.InputSearchComboBox = styled.input`
  border: 1px solid #ededed;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 4px;
  height: 2.9em;
  padding-left: 1em;
  width: 100%;
  outline: none;
  transition: border 0.3s;
  margin-top: 3px;
`
ComboBox.ContainerScrollComboBox = styled.div`
  height: 150px;
  margin-left: 4px;
  position: absolute;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  z-index: 99;
  overflow-y: scroll;
  background: white;
  width: 96%;
`
ComboBox.SelectComboBox = styled.div(
  ({ displayHidden }: { displayHidden?: boolean }) => `
  display: ${displayHidden ? 'none' : 'flex'};
  flex-direction: row;

  :hover {
    background: #d7d7d7;
  }

  input {
    padding: 0.4rem;
  }

  label {
    padding: 0.4rem;
    width: 100%;
  }
`
)

const ComboBoxEstados = ({ disabled: editing }) => {
  const { dadosAluno, estados } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const { ContainerComboBox, ContainerScrollComboBox, InputSearchComboBox, SelectComboBox } =
    ComboBox
  const [inputValue, setInputValue] = useState<string>(dadosAluno.estado || '')
  const [visibleScrollComboBox, setVisibleScrollComboBox] = useState<boolean>(Boolean())
  const dataLabel = 'sigla'

  const handleInput = (input: any) => {
    setInputValue(input.target.value)
  }

  return (
    <ContainerComboBox id="ContainerComboBox">
      <label htmlFor="InputSearchEstados">Estado</label>
      <InputSearchComboBox
        id="InputSearchEstados"
        type="text"
        disabled={editing}
        onInput={handleInput}
        value={inputValue}
        onClick={() => {
          setVisibleScrollComboBox(true)
        }}
        onBlur={() => {
          setTimeout(() => {
            setVisibleScrollComboBox(false)
          }, 300)
        }}
      />

      {visibleScrollComboBox && (
        <ContainerScrollComboBox>
          {estados?.map((m, key) => {
            const temp = {
              displayHidden: !String(m[dataLabel]).toUpperCase().includes(inputValue.toUpperCase()),
              ...m,
            }

            return (
              <SelectComboBox displayHidden={temp.displayHidden} key={key}>
                <label htmlFor={String(key)}>{temp[dataLabel]}</label>
                <input
                  id={String(key)}
                  name="estado"
                  style={{ display: 'none' }}
                  onClick={(e: any) => {
                    setInputValue(e.target.value)
                    dispatch(
                      ProgressoStore.actions.updateDadosAluno({
                        [e.target.name]: e.target.value,
                        municipio: '',
                      })
                    )
                  }}
                  value={temp[dataLabel]}
                />
              </SelectComboBox>
            )
          })}
        </ContainerScrollComboBox>
      )}
    </ContainerComboBox>
  )
}

const ComboBoxMunicipios = ({ disabled: editing }) => {
  const { dadosAluno, municipios } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const { ContainerComboBox, ContainerScrollComboBox, InputSearchComboBox, SelectComboBox } =
    ComboBox
  const [estadoValue, setEstadoValue] = useState<string>(dadosAluno.estado || '')
  const [inputValue, setInputValue] = useState<string>(dadosAluno.municipio || '')
  const [visibleScrollComboBox, setVisibleScrollComboBox] = useState<boolean>(Boolean())
  const dataLabel = 'nome'

  const handleInput = (input: any) => {
    setInputValue(input.target.value)
  }

  useEffect(() => {
    if (estadoValue !== dadosAluno.estado) {
      setEstadoValue(dadosAluno.estado)
      setInputValue('')
    }
  }, [dadosAluno.estado])

  return (
    <ContainerComboBox>
      <label htmlFor="InputSearchMunicipios">Município</label>
      <InputSearchComboBox
        id="InputSearchMunicipios"
        type="text"
        disabled={editing}
        onInput={handleInput}
        value={inputValue}
        onClick={() => {
          setVisibleScrollComboBox(true)
        }}
        onBlur={() => {
          setTimeout(() => {
            setVisibleScrollComboBox(false)
          }, 300)
        }}
      />

      {visibleScrollComboBox && (
        <ContainerScrollComboBox>
          {municipios?.map((m, key) => {
            const temp = {
              displayHidden: !String(m[dataLabel]).toUpperCase().includes(inputValue.toUpperCase()),
              ...m,
            }

            return (
              <SelectComboBox displayHidden={temp.displayHidden} key={key}>
                <label htmlFor={String(key)}>{temp[dataLabel]}</label>
                <input
                  id={String(key)}
                  name="municipio"
                  style={{ display: 'none' }}
                  onClick={(e: any) => {
                    setInputValue(e.target.value)
                    dispatch(
                      ProgressoStore.actions.updateDadosAluno({ [e.target.name]: e.target.value })
                    )
                  }}
                  value={temp[dataLabel]}
                />
              </SelectComboBox>
            )
          })}
        </ContainerScrollComboBox>
      )}
    </ContainerComboBox>
  )
}

const GerarNovoToken = () => {
  const { token } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()

  const handleCopyToClipboard = (text: string) => {
    copyToClipboard(text)
    toast('link para mudança de senha copiado')
  }

  return (
    <DisplayFlex style={{ display: 'flex', justifyContent: 'flex-end' }} className="entire-col">
      {token ? (
        <DisplayFlex style={{ alignItems: 'flex-end' }}>
          <Label
            label="Link para criar senha"
            value={`https://app.newdriver.com.br/criar-senha/${token}`}
          />
          <CopyButton
            width={18}
            onClick={() =>
              handleCopyToClipboard(`https://app.newdriver.com.br/criar-senha/${token}`)
            }
          />
        </DisplayFlex>
      ) : (
        <Button onClick={() => dispatch(ProgressoStore.thunks.gerarNovoToken())}>
          Gerar novo token
        </Button>
      )}
    </DisplayFlex>
  )
}

const AcoesDetran = () => {
  const dispatch = ProgressoStore.useDispatch()
  const { matricula } = ProgressoStore.useState()

  const showModal = (contentMessage) => {
    return new Promise((resolve, reject) => {
      ModalAntd.confirm({
        title: '',
        content: contentMessage,
        onOk: () => resolve(true),
        onCancel: () => reject(false),
      })
    })
  }

  return (
    <DisplayFlex style={{ display: 'flex', justifyContent: 'flex-end' }} className="entire-col">
      <Show condition={matricula.uf === 'SP'}>
        <Button onClick={() => dispatch(ProgressoStore.thunks.consultarMatricula(matricula))}>
          Consultar Status Matricula
        </Button>
      </Show>
      <Button
        onClick={async () => {
          const confirmar = await showModal(
            `Tem certeza que deseja EXCLUIR a matrícula ${matricula.id}?`
          )
          if (!confirmar) return
          dispatch(ProgressoStore.thunks.cancelarMatricula(matricula))
        }}
      >
        Cancelar Matrícula
      </Button>
      <Show condition={matricula.uf === 'SP'}>
        <Button
          onClick={async () => {
            const confirmar = await showModal(
              `Tem certeza que deseja CANCELAR a conclusão da matrícula ${matricula.id}?`
            )
            if (!confirmar) return
            dispatch(ProgressoStore.thunks.cancelarConclusaoMatricula(matricula))
          }}
        >
          Cancelar Conclusão
        </Button>
      </Show>
    </DisplayFlex>
  )
}

const Sessoes = () => {
  const { sessoesEstudo } = ProgressoStore.useState()
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Início</th>
            <th>Fim</th>
            <th>Duração</th>
          </tr>
        </thead>
        <tbody>
          {sessoesEstudo?.map((se) => (
            <tr key={se.id}>
              <td>{se.id}</td>
              <td>
                <FormatedDatetime datetime={se.inicio} />
              </td>
              <td>
                <FormatedDatetime datetime={se.fim} />
              </td>
              <td>{_getDifference(se.inicio, se.fim)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const Acompanhamento = () => {
  const { progressos } = ProgressoStore.useState()
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Matrícula Id</th>
            <th>Data</th>
            <th>Tipo</th>
            <th>Progresso na página</th>
            <th>Progresso no módulo</th>
            <th>Módulo</th>
          </tr>
        </thead>
        <tbody>
          {progressos?.map((p) => (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>{p.matriculaId}</td>
              <td>
                <FormatedDatetime datetime={p.createdAt} />
              </td>
              <td>
                {p.conteudoItem.tipo}
                {p.conteudoItem.tipo === 'VIDEO' ? ` ${+p.porcentagem.toFixed(2)}%` : ''}
              </td>
              <td>
                {p.conteudoItem.ordenacao} / {p.conteudoItem.total}
              </td>
              <td>
                {p.conteudoItem.conteudoPagina.numero} / {p.conteudoItem.conteudoPagina.total}{' '}
              </td>
              <td>{p.conteudoItem.conteudoPagina.modulo.nome}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const ValidacoesFaciais = () => {
  const { validacoesFaciais } = ProgressoStore.useState()

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Data</th>
            <th>Validador</th>
            <th>Resultado Validação</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {validacoesFaciais?.map((vf, i, arr) => (
            <tr key={vf.id}>
              <td>{vf.id}</td>
              <td>
                <FormatedDatetime datetime={vf.createdAt} />
              </td>
              <td>{vf.servicoValidacao}</td>
              <td>{vf.resultadoValidacao}%</td>
              <td>
                <Modal trigger={<Button>Abrir Foto</Button>}>
                  <Modal.Header>Foto validada</Modal.Header>
                  <Modal.Content>
                    <ModalFoto foto={vf.arquivoFoto} />
                  </Modal.Content>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const Avaliacoes = () => {
  const { avaliacoes } = ProgressoStore.useState()
  return (
    <div>
      <Table>
        <thead>
          <th> Id </th>
          <th> Matrícula Id </th>
          <th> Data </th>
          <th> Pontuação </th>
          <th> Aprovada </th>
          <th> Módulo </th>
        </thead>
        <tbody>
          {avaliacoes?.map((a) => (
            <tr key={a.id}>
              <td>{a.id}</td>
              <td>{a.matriculaId}</td>
              <td>
                <FormatedDatetime datetime={a.createdAt} />
              </td>
              <td>{a.pontuacao}</td>
              <td>{a.status === 'APROVADA' ? 'Sim' : 'Não'}</td>
              <td>{a.moduloId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const EstatisticasDeEstudo = () => {
  const { estatisticasDeEstudo } = ProgressoStore.useState()
  return (
    <Table>
      <thead>
        <th> Id </th>
        <th> Matrícula Id </th>
        <th> Módulo </th>
        <th> Página atual </th>
        <th> Página mais avançada </th>
        <th> Percentual na página </th>
        <th> Percentual no módulo </th>
        <th> Percentual mais avançado</th>
        <th> Nota na avaliação </th>
        <th> Módulo completo</th>
      </thead>
      <tbody>
        {estatisticasDeEstudo?.map((e) => (
          <tr key={e.id}>
            <td>{e.id}</td>
            <td>{e.matriculaId}</td>
            <td>{e.modulo.nome}</td>
            <td>{e.paginaNumero}</td>
            <td>{e.paginaNumeroMaisAvancada}</td>
            <td>{e.percentualProgressoPagina}</td>
            <td>{e.percentualProgressoGeral}</td>
            <td>{e.percentualProgressoMaisAvancado}</td>
            <td>{e.desempenhoAvaliacao}</td>
            <td>{e.moduloCompleto ? 'Sim' : ''}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const OutrosCursos = () => {
  const { outrosCursos } = ProgressoStore.useState()
  const { getDataFormatada } = OutrosCursos
  return (
    <Table>
      <thead>
        <th> Id Matricula </th>
        <th> Id Aluno </th>
        <th> Curso </th>
        <th> Status </th>
        <th> Nota </th>
        <th> Data Iníncio </th>
        <th> Data Final </th>
        <th></th>
      </thead>
      <tbody>
        {outrosCursos?.matriculas?.map((matricula, index) => (
          <tr key={index}>
            <td>{matricula?.id}</td>
            <td>{matricula?.alunoId}</td>
            <td>{matricula?.curso?.nome}</td>
            <td>
              <ContainerTag matricula={matricula} />
            </td>
            <td>{matricula?.nota}</td>
            <td>{getDataFormatada(matricula?.dataInicioCurso)}</td>
            <td>{getDataFormatada(matricula?.dataFimCurso)}</td>
            <td>
              <IconSerach cursoId={matricula?.curso?.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

OutrosCursos.getDataFormatada = (data: Date) => {
  return data ? format(new Date(data), 'dd/MM/yyyy HH:mm') : ''
}

const ContainerTag = ({ matricula }: { matricula: MatriculaModel }) => {
  const { Tag, catchColorTag, catchStatusNome } = ContainerTag

  return (
    <Tag getTagColor={catchColorTag(matricula?.statusDetran)}>
      <span>{catchStatusNome(matricula?.statusDetran)}</span>
    </Tag>
  )
}
ContainerTag.Tag = styled.div<{ getTagColor: { backgroundColor: string; color: string } }>(
  ({ getTagColor }) => `
  padding: 4px 6px;
  border-radius: 15px;
  background-color: ${getTagColor?.backgroundColor};
  color: ${getTagColor?.color};
  display: grid;
  place-items: center;
  span {
    font-size: 14px;
  }
`
)
ContainerTag.catchColorTag = (statusDetran: string) => {
  if (statusDetran === 'MATRICULADO') return { backgroundColor: '#E0E2FF', color: '#373ECE' }
  if (statusDetran === 'FINALIZADO') return { backgroundColor: '#E7FFD9', color: '#599534' }
  if (statusDetran === 'AGUARDANDO_PAGAMENTO')
    return { backgroundColor: '#FCEDE2', color: '#FF9722' }
}
ContainerTag.catchStatusNome = (statusDetran: string) => {
  if (statusDetran === 'FINALIZADO') return 'Finalizado'
  if (statusDetran === 'MATRICULADO') return 'Matriculado'
  if (statusDetran === 'AGUARDANDO_PAGAMENTO') return 'Aguardando Pagamento'
}

const IconSerach = ({ cursoId }) => {
  const { Layout } = IconSerach
  const history = useHistory()
  const handleInfoCurso = (cursoId: number) => history.push(`/cursos/${cursoId}/editar`)

  return (
    <Layout onClick={() => handleInfoCurso(cursoId)}>
      <img src={iconSearch} />
    </Layout>
  )
}
IconSerach.Layout = styled.div`
  display: grid;
  place-items: center;
  border: 2px solid #585858;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
  img {
    width: 15px;
    filter: invert(100%);
  }
`

const BotaoVoltar = () => {
  const history = useHistory()
  return (
    <Button style={{ margin: '25px auto', maxWidth: 1024 }} onClick={history.goBack} circular>
      <Icon name="arrow left" />
      Voltar
    </Button>
  )
}

const FormatedDatetime = ({ datetime }) => {
  if (!datetime) return null
  return (
    <>
      {format(parseISO(datetime), 'dd/MM/yyyy HH:mm')}
      <span style={{ opacity: 0.5 }}>{format(parseISO(datetime), ':ss')}</span>
    </>
  )
}

const Label = ({ label, value, ...props }) => {
  return (
    <div {...props}>
      <div> {label} </div>
      <div>
        {' '}
        <strong>{value}</strong>{' '}
      </div>
    </div>
  )
}

const ModalFoto = ({ foto }: { foto: string }) => {
  const { dadosAluno } = ProgressoStore.useState()
  const cpf = dadosAluno?.cpf.replace(/[-.]/g, '')
  const fotoWithSas = useDecorateBlobWithSas({ blobPath: cpf + '/' + foto })
  return <img style={{ maxWidth: '100%' }} src={fotoWithSas} />
}

function AplicarCupom() {
  const { matricula } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const [cupom, setCupom] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { usuarioId } = useParams<ProgressoParams>()

  async function handleAplicarCupom() {
    setLoading(true)
    try {
      await axios.post('matriculas/aplicar-cupom', {
        matriculaId: matricula.id,
        cupomCodigo: cupom,
      })
      dispatch(ProgressoStore.thunks.getDados(usuarioId, matricula.id))
    } catch (error) {
      const message = error?.response?.data.message ?? 'Erro ao aplicar o cupom!'
      toast(message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Show
      condition={
        !matricula?.cobranca?.dataPagamento &&
        startOfDay(new Date(matricula?.cobranca?.dataVencimento)) >= startOfToday()
      }
    >
      <div>
        <Label
          label="Cupom"
          className="entire-col"
          value={
            <DisplayFlex style={{ gap: 12 }}>
              <TextFieldInput
                name="nome"
                value={cupom}
                placeholder="Novo cupom"
                onChange={(e) => setCupom(e.target.value)}
              />
              <Button onClick={handleAplicarCupom} loading={loading} style={{ height: 42 }}>
                Aplicar
              </Button>
            </DisplayFlex>
          }
        />
      </div>
    </Show>
  )
}

const CopyButton = styled(CopyIcon)`
  fill: darkgray;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`

const formatDate = (date, dateFormat?) => {
  if (!date) return null
  try {
    return format(parseISO(date), dateFormat ?? 'dd/MM/yyyy')
  } catch {
    return date
  }
}

function _getDifference(inicio, fim) {
  const diferenca = differenceInMinutes(parseISO(fim), parseISO(inicio))
  const horas = ~~(diferenca / 60)
  const minutos = diferenca % 60
  const _pluralize = (str, size) => (size === 1 ? str : str + 's')
  const strMinutos = `${('' + minutos).padStart(2, '0')} ${_pluralize('minuto', minutos)}`
  const strHoras = `0${horas} ${_pluralize('hora', horas)}`
  if (!horas && !minutos) return 'Menos de um minuto'
  if (!horas) return strMinutos
  if (horas && !minutos) return strHoras
  return `${strHoras} e ${strMinutos}`
}

function Isencao() {
  const dispatch = ProgressoStore.useDispatch()
  const { matricula } = ProgressoStore.useState()
  const [motivoIsencao, setMotivoIsencao] = useState('')

  const handleIsencao = async () => {
    if (!motivoIsencao) return toast.error('Informe o motivo para fazer a isenção!')
    const result = await ConfirmAlert({
      title: 'Deseja fazer a isenção dessa matrícula?',
      content: '',
    })
    if (!result) return
    dispatch(
      ProgressoStore.thunks.emitirIsencao({
        alunoId: matricula.alunoId,
        matriculaId: matricula.id,
        motivoIsencao,
      })
    )
  }

  return (
    <Show condition={!matricula?.dataPagamento}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
        }}
        className="entire-col"
      >
        <TextFieldInput
          name="motivo_isencao"
          label="Motivo Isenção"
          value={motivoIsencao}
          onChange={(e) => setMotivoIsencao(e.target.value)}
          className="entire-col"
          style={{ width: 300 }}
        />
        <Button onClick={handleIsencao}>Isentar</Button>
      </div>
    </Show>
  )
}

function Cupom() {
  const { matricula } = ProgressoStore.useState()
  return (
    <Show condition={matricula?.cupom}>
      <div className="entire-col">
        <Label label="Cupom aplicado" value={matricula?.cupom?.codigo} />
      </div>
    </Show>
  )
}

function ReemitirCobranca() {
  const { matricula } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const reemitirCobranca = () => {
    dispatch(ProgressoStore.thunks.reemitirCobranca())
  }
  return (
    <Show
      condition={
        !matricula?.cobranca?.dataPagamento &&
        startOfDay(new Date(matricula?.cobranca?.dataVencimento)) < startOfToday()
      }
    >
      <div className="entire-col">
        <Button style={{ marginLeft: 'auto' }} onClick={reemitirCobranca}>
          Reemitir Cobrança
        </Button>
      </div>
    </Show>
  )
}

function AlteracaoValor() {
  const { matricula } = ProgressoStore.useState()
  const dispatch = ProgressoStore.useDispatch()
  const { usuarioId } = useParams<ProgressoParams>()
  const [valor, setValor] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleAlterarValor() {
    setLoading(true)
    try {
      await axios.post('matriculas/altera-valor', {
        matriculaId: matricula.id,
        valorNovo: valor,
      })
      toast.success('Valor alterado com sucesso!')
      dispatch(ProgressoStore.thunks.getDados(usuarioId, matricula.id))
    } catch (error) {
      const message = error?.response?.data.message ?? 'Erro ao alterar o valor!'
      toast(message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Show
      condition={
        !matricula?.cobranca?.dataPagamento &&
        startOfDay(new Date(matricula?.cobranca?.dataVencimento)) >= startOfToday()
      }
    >
      <Label
        label="Alteração de valor da cobrança"
        className="entire-col"
        value={
          <DisplayFlex style={{ gap: 12 }}>
            <TextFieldInput
              name="valor"
              value={valor}
              placeholder="Novo valor"
              onChange={(e) => setValor(e.target.value)}
            />
            <Button onClick={handleAlterarValor} loading={loading} style={{ height: 42 }}>
              Alterar
            </Button>
          </DisplayFlex>
        }
      />
    </Show>
  )
}
